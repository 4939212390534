.rating {
    display: flex;
    align-items: center;

    font-family: "Golos";
    font-size: .75em;
    font-weight: 400;
    line-height: 1.67em;
    letter-spacing: -.03em;

    border: 1px solid #5CDE80;
    border-radius: 34px;

    padding: .25em 1em;

    &--right {
        margin-left: auto;
    }

    .icon {
        width: .83em;
        height: .83em;

        margin-right: .33em;

        fill: #5CDE80;
    }

    &__text {
        color: #5CDE80;
    }
}