$height: 3.5rem;
$padding: 1em 16px;

.dropdown {
    position: relative;

    z-index: 1;

    width: 100%;
    height: $height;

    transition: z-index 0 .5s;

    &.is-open {
        z-index: 100;
        transition: z-index 0 0;
    }

    &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        
        background: white;
        box-shadow: inset 0px 0px 0px 1px #e4e4e4;
        border-radius: 8px;

        max-height: $height;

        overflow: hidden;

        transition: max-height .5s;

        .dropdown.is-open & {
            max-height: calc($height + (1.875em * 5));
        }
    }

    &__input {
        width: 100%;
        height: $height;

        padding: $padding;
        border: 0 solid transparent;
        color: #161E34;
        background: transparent;
        font-size: 0.95em;
        line-height: 1em;
        // background: white;
        box-shadow: none;

        // &:read-only {
        //     pointer-events: none;
        // }
    }

    &__arrow {
        position: absolute;
        top: 1em;
        right: 16px;

        width: 1.5em;
        height: 1.5em;

        transform: scale(-1);

        transition: transform .2s;

        .dropdown.is-open & {
            transform: scale(1);
        }

        .icon {
            width: 100%;
            height: 100%;

            fill: #6F7588;
        }
    }

    &__options {
        // position: absolute;
        // top: $height;
        left: 0;
        right: 0;
        z-index: 0;

        // max-height: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        // overflow: hidden;

        transform: translate3d(0, 0, 0);

        transition: transform .5s;

        pointer-events: none;

        .dropdown.is-open & {
            position: relative;

            top: 0;

            max-height: calc(1.875em * 5);


            transform: translate3d(0, 0, 0);

            pointer-events: all;
        }

        .simplebar-wrapper {
            width: 100%;

            .simplebar-content-wrapper {
                // height: 100%;
            }
        }
    }

    &__option {
        width: 100%;

        font-size: .875em;
        line-height: 1.57em;
        font-feature-settings: 'pnum' on, 'lnum' on;
        color: #161E34;
        text-align: left;

        padding: .5em 16px;

        &--loading {
            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: none;

            .image {
                width: 2.5rem;
                height: 2.5rem;

            }
        }

        .black {
            color: #000;
            font-weight: 400;
        }

        &:hover {
            background: #e4e4e4;
        }

        &.is-active {
            color: #000;
        }
    }
}