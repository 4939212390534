.card {
    margin-top: em(30);
	
    &__content {
        margin-top: 1.5em;
        transition: transform .3s .6s, opacity .3s .6s;
    }

    &__btn {
        margin-top: em(34);
        transition: transform .3s 2.7s, opacity .3s 2.7s;

        .reviews & {
            transition: transform .3s 1.7s, opacity .3s 1.7s;
        }
    }

    &__answers {
        margin-top: 1em;
        padding-top: 1em;
        border-top: 1px solid #eee;
    }

    &__answers_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:nth-child(1) {
            transform: translate3d(-3em,0,0);
            transition: transform .3s 1.6s, opacity .3s 1.6s;
        }
        &:nth-child(2) {
            transform: translate3d(3em,0,0);
            transition: transform .3s 1.7s, opacity .3s 1.7s;
        }
        &:nth-child(3) {
            transform: translate3d(-3em,0,0);
            transition: transform .3s 1.8s, opacity .3s 1.8s;
        }
        &:nth-child(4) {
            transform: translate3d(3em,0,0);
            transition: transform .3s 1.9s, opacity .3s 1.9s;
        }
        &:nth-child(5) {
            transform: translate3d(-3em,0,0);
            transition: transform .3s 2s, opacity .3s 2s;
        }
        &:nth-child(6) {
            transform: translate3d(3em,0,0);
            transition: transform .3s 2.1s, opacity .3s 2.1s;
        }

        & + & {
            margin-top: 0.75em;
        }
    }

    &__answers_name {
        font-size: em(14);
        line-height: em(20, 14);
        font-family: $title-font;
        font-weight: 400;
    }

    &__answers_span {
        color: $c-primary;
        font-size: em(14);
        line-height: em(20, 14);
        font-family: $title-font;
        font-weight: 400;

        &--grey {
            color: $c-text;
        }
    }

    .agencies_card__bottom {
        margin-top: em(26);
    }
}