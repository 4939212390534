.connect_form {
    // transition: transform .3s 1.6s, opacity .3s 1.6s;

    &__block {

        .section_block__title {
            margin-bottom: 0.6em;
            transform: translate3d(3em, 0, 0);
        }

        & + & {
            margin-top: 1em;
        }

        &:nth-child(2) {
            
            .section_block__title {
                transition: transform .3s .7s, opacity .3s .7s;

                .form & {
                    transition: transform .3s 1.9s, opacity .3s 1.9s;
                }
            }

            .dropdown, .input_wrapper {
                transition: transform .3s .8s, opacity .3s .8s;

                .form & {
                    transition: transform .3s 2s, opacity .3s 2s;
                }
            }
            
        }
        &:nth-child(3) {
            
            .section_block__title {
                transition: transform .3s .9s, opacity .3s .9s;

                .form & {
                    transition: transform .3s 2.1s, opacity .3s 2.1s;
                }
            }

            .dropdown, .input_wrapper {
                transition: transform .3s 1s, opacity .3s 1s;

                .form & {
                    transition: transform .3s 2.2s, opacity .3s 2.2s;
                }
            }
            
        }
        &:nth-child(4) {
            
            .section_block__title {
                transition: transform .3s 1.1s, opacity .3s 1.1s;

                .form & {
                    transition: transform .3s 2.3s, opacity .3s 2.3s;
                }
            }

            .input_wrapper {
                transition: transform .3s 1.2s, opacity .3s 1.2s;

                .form & {
                    transition: transform .3s 2.4s, opacity .3s 2.4s;
                }
            }
            
        }
        &:nth-child(5) {
            
            .section_block__title {
                transition: transform .3s 1.3s, opacity .3s 1.3s;

                .form & {
                    transition: transform .3s 2.5s, opacity .3s 2.5s;
                }
            }

            .input_wrapper {
                transition: transform .3s 1.4s, opacity .3s 1.4s;

                .form & {
                    transition: transform .3s 2.6s, opacity .3s 2.6s;
                }
            }
            
        }
        &:nth-child(6) {
            
            .section_block__title {
                transition: transform .3s 1.5s, opacity .3s 1.5s;

                .form & {
                    transition: transform .3s 2.7s, opacity .3s 2.7s;
                }
            }

            .input_wrapper {
                transition: transform .3s 1.6s, opacity .3s 1.6s;

                .form & {
                    transition: transform .3s 2.8s, opacity .3s 2.8s;
                }
            }
            
        }
    }

    &__btn {
        margin-top: em(20);
        width: 100%;

        transition: transform .3s 1.4s, opacity .3s 1.4s;

        .form & {
            transition: transform .3s 2.5s, opacity .3s 2.5s;
        }

        .application_form & {
            transition: transform .3s 2.5s, opacity .3s 2.5s;
        }
    }
}