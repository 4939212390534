
.modal_form {
	position: relative;

	width: 26em;
	
	margin-top: 5em;
	margin-bottom: 5em;
	padding: 2.5em;
	border-radius: 14px;

	background: white;
	transform: translate3d(0, 5em, 0);
	opacity: 0;

	transition: transform .35s, opacity .35s;

	@media(max-width: 500px) {
		width: 90vw;
	}

	&--success {
		margin: auto;
	}

	&:before {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 11;

		width: 4em;
		height: 4em;
		margin: auto;

		// background: url(#{$assetsPath}/img/spinner.svg) no-repeat center;
		background-size: contain;
		opacity: 0;

		transition: all .3s;

		content: "";
		pointer-events: none;
	}

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 10;

		border-radius: 14px;

		background: rgba(#121212, .11);
		opacity: 0;

		transition: all .3s;

		content: "";
		pointer-events: none;
	}

	&.on-request:before,
	&.on-request:after {
		opacity: 1;

		pointer-events: all;
	}

	.is-active & {
		transform: none;
		opacity: 1;
	}

	&__close {
		top: 1.25em;
		right: 1.25em;

		width: 24px;
		height: 24px;
	}

	&__title {
		font-weight: 700;
		font-size: 1.8em;
	}

	&__text {
		margin-top: 1.25rem;

		color: #747474;
		font-weight: 400;
		font-size: .9em;
		line-height: 1.5em;

		.strong {
			font-weight: 600;
			font-size: 1em;
		}
	}

	&__inputs {
		margin-top: 1.5rem;
	}

	&__wrap {
		position: relative;

		&:nth-child(1) {
			z-index: 2;
		}

		& + & {
			margin-top: 1.5rem;
		}
	}

	&__caption {
		margin-bottom: 1.5rem;

		font-weight: 500;
		line-height: 1.25em;
	}

	.order_form {

		&__wrap {
			margin-top: 1.5rem;
		}

		&__first {
			margin-bottom: 1.5rem;
		}

		&__subtitle {
			margin-bottom: 1.5rem;

			font-size: 1rem;
		}

		&__hidden_wrap {

			&.is-visible {
				margin-bottom: 1.5rem;
			}
		}

		&__textarea {
			margin-top: 0;
		}
	}

	&__button {
		width: 100%;
		height: 3.5rem;
		margin-top: 1.5rem;

		font-weight: 300;
	}

	&__bg {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 12em;
		height: 12em;
		margin: 2.5rem auto;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}
}