.checkbox__field {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.checkbox__label {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 0.875em;
    cursor: pointer;
}

.checkbox__label:before {
    content: '';
    width: 1.3em;
    height: 1.3em;
    box-shadow: inset 0 0 0 2px #0d74d3;
    border-radius: 50%;
    flex: none;
    margin-right: 1em;
}
.checkbox__title {
	display: block;
    color: #161E34;
    font-weight: 600;
    font-family: 'Golos';
    margin-bottom: 0.6em;
}
.checkbox__text  {
	line-height: 1.2;
}

.checkbox__label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1.3em;
    height: 1.3em;
    background: #0d74d3;
    border-radius: 50%;
    transform: scale(0);
    transition: transform .17s;
}

.checkbox__field:checked + .checkbox__label:after {
    transform: scale(0.55);
}


@media (max-width: 500px) {
	.checkbox__text {
		font-size: 0.875em;
		line-height: 1.4
	}
	
	.checkbox__title {
		font-size: 1.125em;
		margin-bottom: 0.4em;
	}
	
}