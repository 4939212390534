
.modal {
	position: absolute;

	top: 50%;
	left: 50%;
	z-index: 1;
	
	transform: translate3d(-50%, -50%, 0);

	display: flex;
	align-items: center;
	justify-content: center;
    width: calc(100% - 2em);
    max-width: 31.25em;
	height: 100%;

	// pointer-events: none;

	&__overlay {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;

		width: 100%;
		height: 100%;

		background: linear-gradient(to top, rgba(0, 0, 0, .75), rgba(0, 0, 0, .5));
		opacity: 0;

		transition: all .5s;

		.modals.is-active & {
			opacity: 1;

			pointer-events: all;
		}
	}

	&__content {
		position: relative;
		z-index: 1;


		opacity: 0;

		&--scroll {
			width: 26em;
			height: 100%;

			@media(max-width: 500px) {
				width: 90vw;
			}

			.simplebar-vertical {
				top: 20%;

				height: 60%;
			}
		}

		.is-active & {
			opacity: 1;

			pointer-events: all;
		}
	}
}