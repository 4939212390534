.agencies_card {
	display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: em(20);

    // .card & {
    //     .section_block__text--card {
            
    //     }
    // }

    &__top {
        display: flex;
        align-items: center;
        margin-bottom: 1em;

        .section_block__text {
            margin-top: 0;
        }

        .card & {
            transform: translate3d(3em,0,0);
            transition: transform .3s .7s, opacity .3s .7s;
        }
    }

    &__top_icon {
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        display: flex;
        flex: none;
        align-items: center;
        justify-content: center;
        width: em(56);
        height: em(56);
        margin-right: em(14);

        .image {
            width: em(48);
            height: em(48);
        }
    }

    &__line {
        display: flex;
        align-items: center;
        padding: 0.5em 0;
        border-top: 1px solid #eee;
        border-bottom: 1px solid #eee;
        margin-bottom: 1em;

        .card & {
            transform: translate3d(-3em,0,0);
            transition: transform .3s 1s, opacity .3s 1s;
        }
    }

    &__block {
        display: flex;
        align-items: center;

        & + & {
            margin-left: em(10);
        }

        &:last-child {
            margin-left: auto;
        }
    }

    &__block_icon {
        width: 1.5em;
        height: 1.5em;
        margin-right: em(2);

        .icon {
            fill: $c-primary;
            width: 100%;
            height: 100%;
        }
    }

    &__block_text {
        font-size: em(12);
        letter-spacing: -0.03em;
        font-weight: 400;
    }

    &__bottom {
        margin-top: em(20);
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .card & {
            justify-content: space-between;
            transform: translate3d(-3em,0,0);
            transition: transform .3s 2.4s, opacity .3s 2.4s;
        }
        .reviews & {
            justify-content: space-between;
            transition: transform .3s 1s, opacity .3s 1s;
        }
    }

    &__bottom_link {
        display: flex;
        align-items: center;

        .text {
            font-family: $title-font;
            font-size: 1em;
            line-height: em(22);
            font-weight: 600;
            margin-right: em(2);
        }

        .icon {
            width: 1.5em;
            height: 1.5em;
        }
    }

    &__bottom_right {
        display: flex;
        align-items: center;
    }

    &__like {
        display: flex;
        align-items: center;

        & + & {
            margin-left: em(10);
        }
    }

    &__like_icon {
        width: 1.5em;
        height: 1.5em;

        .icon_up {
            fill: $c-primary;
            width: 100%;
            height: 100%;
        }
        .icon_down {
            fill: $c-text;
            width: 100%;
            height: 100%;
        }
    }

    & + & {
        margin-top: 1.5em;
    }
}