.header {
	margin-top: 1.5em;

    &__container {
        background: white;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: em(14) em(20);

        // opacity: 0;

        // body.is-loaded & {
        //     opacity: 1;
        // }

        // transition-property: opacity;
        // transition-duration: .3s;
        // transition-delay: .5s;
    }

    &__logo {
        width: em(150);
        height: em(28);
    }

    &__menu {
        border: 1px solid #E4E4E4;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: em(12) em(14);

        .icon {
            width: 1em;
            height: 0.75em;
            margin-left: 0.5em;
            fill: $c-black;
        }
    }

    &__menu_text {
        font-size: em(14);
        font-weight: 700;
        font-family: $title-font;
    }
}