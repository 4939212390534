.connect_send {
	margin-top: em(30);

    &__container {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
        border-radius: 12px;
        padding: em(42) em(20) em(25);
        
        .section_block__title {
            transition: transform .3s .6s, opacity .3s .6s;
        }
        .section_block__text {
            transition: transform .3s .9s, opacity .3s .9s;
        }
    }

    &__icon {
        width: em(290);
        height: em(200);
        align-self: center;
        margin-bottom: em(65);
        transform: translate3d((0,0,0)) scale3d(0,0,1);
        transition: transform .5s .3s, opacity .3s .3s;
    }

    &__btn {
        width: 100%;
        margin-top: em(52);
        transition: transform .3s 1.2s, opacity .3s 1.2s;
    }
}