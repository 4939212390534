.agencies {
	margin-top: em(30);

    &__container {
        &--center {
            display: flex;
            justify-content: center;
            align-items: center;

            .title {
                padding: 1em;

                font-family: "Golos", sans-serif;
                font-size: 1.25em;
                font-weight: 500;
                color: #000;

                background: #fff;
                border-radius: 12px;
                width: 100%;

                text-align: center;
            }
        }

        
        .download_text {
            padding: 1em;

            font-family: "Golos", sans-serif;
            font-size: 1.25em;
            font-weight: 500;
            color: #000;

            background: #fff;
            border-radius: 12px;
            width: 100%;

            text-align: center;
        }
    }

    &__content {
        margin-top: 1.5em;
    }

    &__wrap {
        position: relative;

        .agencies_card__bottom_link {
            position: absolute;
            bottom: em(20);
            left: em(20);
        }

        &:nth-child(1) {
            transition: transform .3s .6s, opacity .3s .6s;
        }
        
        &:nth-child(2) {
            transition: transform .3s .9s, opacity .3s .9s;
        }
        
        &:nth-child(3) {
            transition: transform .3s 1.2s, opacity .3s 1.2s;
        }

        & + & {
            margin-top: 1.5em;
        }
    }

    &__btn {
        margin-top: 1.5em;

        width: 100%;
    }
}