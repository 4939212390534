.modal_menu {
	width: em(294);
	height: 100%;
	margin-left: auto;
	top: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: em(24) em(24) em(0) em(24);
	
	opacity: 0;
	transform: translate3d(5em, 0, 0);
	transition: transform .35s, opacity .35s;

	background: white;

	.modals.is-active & {
		transform: none;
		opacity: 1;
		pointer-events: all;

		.content & {
			overflow: hidden;
		}
	}

	&__close {	
		width: em(112);
		height: em(38);
		border-radius: 6px;
		border: 1px solid #E4E4E4;
		position: initial;
		display: flex;
		align-items: center;
		justify-content: center;
		background: white;
		align-self: flex-end;
		flex: none;

		&:hover svg {
			transform: none;
		}

		svg {
			width: 16px;
			height: 16px;
			fill: $c-black;
		}
	}

	&__close_text {
		font-family: $title-font;
		font-weight: 700;
		font-size: em(14);
		margin-right: 0.6em;
	}

	&__logo {
		background: #f9f9f9;
		border-radius: 8px;
		width: 100%;
		padding-top: 1.5em;
		padding-bottom: 1.25em;
		text-align: center;
		margin: 1.5em 0;

		.image {
			width: em(184);
			height: em(34);
		}
	}

	&__block {
		padding: 1.25em 0;
		border-top: 1px solid #eeeeee;
		border-bottom: 1px solid #eeeeee;
		width: 100%;
	}

	&__block_btn {
		height: 4em;
		font-size: em(14);
	}

	&__socials {
		display: flex;
		flex-direction: column;
		margin-top: 1.25em;
		padding-bottom: 1.25em;
	}

	&__socials_link {
		display: flex;
		flex-direction: column;

		& + & {
			margin-top: em(15);
		}
	}

	&__socials_title {
		color: $c-primary;
		font-size: em(14);
		line-height: em(20, 14);
		font-weight: 500;
		font-family: $title-font;
	}

	&__socials_text {
		font-size: 0.75em;
		line-height: 1.25em;
		color: $c-text;
		margin-top: 0.1em;
	}

	.itome {
		margin-top: auto;
		width: 100%;
		padding: 1em 0;
		border-top: 1px solid #e0e0e0;
		display: flex;
		justify-content: center;
		margin-bottom: 0;

		.image {
			width: 148px;
			height: 16px;
		}
	}
}