$paddingSide: 16px;
$paddingTop: 1em;
$width: 100%;
$height: 3.5em;
$bg-color: white;
$border-r: 8px; 
$bg-color-main: white;
$bg-color-error: white;

.input_wrapper {
	position: relative;

	display: flex;
	flex-direction: column;

	// width: $width;
	// height: $height;

	box-shadow: none;

	& + & {
		margin-left: 1em;
	}

	&--textarea {
		height: em(112);
	}

	&--filled {

		.input_wrapper__input:focus + .input_wrapper__label,
		.input_wrapper__input.is-filled + .input_wrapper__label {
			transform: translate3d(-10%, -120%, 0) scale(.8);
		}

	}

	&--outlined {

		.input_wrapper__input:focus + .input_wrapper__label,
		.input_wrapper__input.is-filled + .input_wrapper__label {
			// transform: none;
			opacity: 0;

		}

	}

	&--minimalistic {

		.input_wrapper__input:focus + .input_wrapper__label,
		.input_wrapper__input.is-filled + .input_wrapper__label {
			transform: translate3d(-6%, calc(-2em - 30%), 0) scale(.9);
		}   

	}

	&__input {
		height: 100%;
		padding: $paddingTop $paddingSide;
		border: 0 solid transparent;
		border-radius: $border-r;
		color: $c-black;

		font-size: .95em;

		line-height: 1em;

		width: $width;
		height: $height;

		background: $bg-color;
		box-shadow: none;


		.input_wrapper--filled & {
			padding: 1.5em $paddingSide .5em;

			background: white;
		}

		.input_wrapper--outlined & {
			background: white;
			border: 1px solid #e4e4e4;
		}

		.input_wrapper--outlined.is-disabled & {
			background: #eee;
			border: 1px solid #eee;
			pointer-events: none;
		}

		.input_wrapper--minimalistic & {
			padding-left: 0;
			border-bottom: 1px solid #87909A;
			border-radius: 0;

			background: $bg-color-main;
		}



		.input_wrapper--filled.not-valid & {
			background: black;
			background: $bg-color-error;
		}

		.input_wrapper--outlined.not-valid & {
			// background: $bg-color-main;
			border: 1px solid $c-error;
		}
			
		.input_wrapper--minimalistic.not-valid & {
			border-bottom: 1px solid $c-error;

			background: none;
		}
		
	}

	textarea {
		height: 7rem;

		resize: none;

		font-family: "Inter", sans-serif;
		font-weight: 300;
		line-height: 1.2em;
	}


	&__label {
		position: absolute;
		top: 50%;
		color: $c-text;

		font-weight: 300;

		font-size: em(14);

		line-height: em(22,14);

		box-shadow: none;

		transform: translate3d(0, -50%, 0);

		transition: transform .25s, opacity .1s;

		pointer-events: none;

		textarea ~ & {
			transform: translate3d(0, -50%, 0);
			top: 1.6rem;
		}
        
		.input_wrapper--filled & {
			left: $paddingSide;
		}

		.input_wrapper--outlined & {
			left: $paddingSide;
		}

		.input_wrapper--minimalistic & {
			left: 0;
		}

		.input_wrapper--filled.not-valid & {
			transform: translate3d(-10%, -120%, 0) scale(.8);
		}

		.input_wrapper--outlined.not-valid & {
			color: $c-error;
			opacity: 1;

			background: $bg-color-main;
			transform: translate3d(0, -50%, 0);
		}
			
		.input_wrapper--minimalistic.not-valid & {
			color: $c-error;

			transform: translate3d(-6%, calc(-2em - 30%), 0) scale(.9);
		}
	}

	&__tooltip {
		position: absolute;
		bottom: 0;
		left: 0;

		color: $c-error;
		font-weight: 300;
		font-size: .75em;

		transform: translate3d(0, 150%, 0);

		opacity: 0;

		transition: opacity .25s;

		.input_wrapper--filled & {
			left: $paddingSide;
		}

		.input_wrapper--outlined & {
			left: $paddingSide;
		}

		.input_wrapper--minimalistic & {
			left: 0;
		}

		.not-valid & {
			opacity: 0;
		}
	}

	&__textarea {
		width: 100%;
		height: 100%;
		padding: 1em 1.25em;
		border: 1px solid #E4E4E4;
		background: white;
		border-radius: 8px;
		color: $c-text;
		font-weight: 300;
		font-size: em(14);
		line-height: em(22, 14);
		font-family: $text-font;
		resize: none;
	}
}


