.preloader {
	position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    transition: opacity .25s .25s;
    opacity: 0;

    // display: none !important;

    // &::before {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 4px;
    //     background: #E86C5B;
    //     transform: scale3d(0,1,1);
    //     transform-origin: left;
    //     content: "";
    // }

    .on-loaded ~ & {
        opacity: 1;
    }
    .disabled & {
        pointer-events: none;
        opacity: 0;
    }

    .is-loaded ~ & {
        pointer-events: none;
        opacity: 0;
    }
    
    &__img {
        width: em(355);
        height: em(96);
        margin-bottom: em(300);
        margin-top: auto;

        @media(max-width:500px) {
            height: 3em;
            margin-bottom: 15em;
        }
    }


    &__load {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 1.2em;
        margin-bottom: 4em;
    }
    &__load_line {
        position: relative;
        width: em(240);
        height: 1px;
        background: #d9d9d9;

        &::before {
            content: '';
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            background: $c-primary;
            top: 0;
            left: 0;
            transform: scale3d(0,1,1);
            transform-origin: left;
            transition: transform 5s;
            animation: loading 5s .5s forwards;
        }
    }
    
}