// colors

$c-primary: #1089F9;
$c-text: #6F7588;
$c-grey: #E4E4E4;
$c-green: #5CDE80;
$c-light-grey: #eee;
$c-black: #161E34;

$c-error: #F2453D;
$c-success: #4CAF50;

// fonts

$title-font: 'Golos', sans-serif;
$text-font: 'MuseoSansCyrl', sans-serif;

// utils

$assetsPath: "/assets";
// $assetsPath: '/themes/itome/assets';


[anim="ripple"] {
    
    //  Customize effect by defining the following
    //  variables on the parent element 
    //  --ripple-background: white;
    //  --ripple-opacity: 0.3;  
    //  --ripple-duration: 600ms;      
    //  --ripple-easing: linear;
    
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position:absolute;
        display: block;
        background: rgba(255, 255, 255, 0.7);      
        border-radius: 50%;
        pointer-events: none; 
        
        //  position and size 
        top: calc(var(--y) * 1px);
        left: calc(var(--x) * 1px);
        width:  calc(var(--d) * 1px);
        height: calc(var(--d) * 1px);

        //  animated properties
        opacity: calc(var(--o, 1) * var(--ripple-opacity, 0.3));                
        transition: calc(var(--t, 0) * var(--ripple-duration, 600ms)) var(--ripple-easing,linear);   
        transform: translate(-50%, -50%) scale(var(--s, 1));
        transform-origin: center;
    }
}

$defaultPixel: 16;

@function em($pixel, $fontSize : $defaultPixel) {
    @return calc($pixel / $fontSize) + em;
}