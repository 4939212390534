.main {
    &__top {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
        border-radius: 12px;
        padding: em(22) em(20);
        
        -webkit-transition: transform .3s 0s, opacity .3s 0s;
        transition: transform .3s 0s, opacity .3s 0s;

        
    }

    &__top_video {
        position: relative;

        width: 100%;
        height: em(300);
        margin-bottom: em(0);
        transform: translate3d((0,0,0)) scale3d(0,0,1);
        transition: transform .3s .3s, opacity .3s .3s;
        -webkit-transition: transform .3s .3s, opacity .3s .3s;

        border-radius: 12px;

        overflow: hidden;

        cursor: pointer;

        &::before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            background: rgba(0, 0, 0, 0.138);
        }

        .image {
            width: 100%;
            height: 100%;

            object-fit: contain;
        }

        .video_play {
            position: absolute;

            top: 50%;
            left: 50%;


            // width: em(40);
            // height: em(40);

            transform: translate3d(-50%, -50%, 0);
        }
    }
    

    &__btn {
        margin: 1em 0;
        transition: transform .3s .6s, opacity .3s .6s;
        -webkit-transition: transform .3s .6s, opacity .3s .6s;
    }
}