.section_block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&--center {
		width: 100%;
		align-items: center;
		text-align: center;
	}

	&__title {
		color: $c-black;

		font-weight: 600;

		font-size: em(18);
		line-height: em(24, 18);
		font-family: $title-font;

		&--small {
			font-size: em(14);
			line-height: em(20, 14);
		}

		&--big {
			font-size: em(20);
			line-height: em(24, 20);
		}

		.main__top & {
			transition: transform .3s .4s, opacity .3s .4s;
		}
		.main_info & {
			transition: transform .3s 1s, opacity .3s 1s;

			.form & {
				transition: transform .3s .3s, opacity .3s .3s;
			}
		}
	}

	&__text {
		margin-top: em(5);
		font-size: em(14);
		color: #6A6A6A;
		font-weight: 300;
		line-height: em(22,14);

		.strong {
			font-weight: 600;
		}

		&--black {
			color: $c-black;
			margin-top: 0;
		}

		&--card {
			transform: translate3d(3em,0,0);
            transition: transform .3s 1.3s, opacity .3s 1.3s;
		}

		.main__top & {
			transition: transform .3s .5s, opacity .3s .5s;
		}

		.main_info & {
			transform: translate3d(3em, 0, 0);
		}
	}

	&__buttons {
		display: flex;
		align-items: center;
		margin-top: 2rem;

		@media(max-width: 500px) {
			flex-direction: column;
			width: 100%;
		}
	}

	&__button {

		& + & {
			margin-left: 1em;

			@media(max-width: 500px) {
				width: 100%;
				margin-top: 1em;
				margin-left: 0;
			}
		}
	}

	&__link {
		color: $c-primary;
		font-weight: 700;
		text-decoration: underline;

		transition: color .25s;
		
		&:hover {
			color: black;
		}
	}

	&__nav {
		display: flex;
		align-items: center;

		@media(max-width: 500px) {
			display: none;
		}
	}

	&__nav_button {
		width: 32px;
		height: 32px;
		border: 1px solid none;
		border-radius: 50%;

		text-align: center;

		background: #323232;

		transition: border .25s, background .25s;

		&.swiper-button-disabled {
			border: 1px solid #D9D9D9;

			background: none;

			svg {
				width: 30%;
				height: 100%;
	
				fill: #D9D9D9;
			}

		}


		& + & {
			margin-left: .5rem;
		}

		svg {
			width: 30%;
			height: 100%;

			fill: #D9D9D9;

			.related_products & {

				fill: black
				
			}
		}

		&--prev {

			svg {
				transform: scaleX(-1);
			}
		}

		
	}
}