.itome {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	overflow: visible;;

	color: #ffffff;
	

	@media(max-width: 500px) {
		margin: 2em 0;
	}

	&:hover .itome_tooltip {
		transform: translateX(calc(-50%)) translateY(-1vh);
		opacity: 1;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 15px;
		height: 15px;
		margin-right: .5em;
		border-radius: 50%;

		background: #006EFF;

		svg {
			width: 45%;
			height: 45%;

			fill: white;
		}
	}

	&__title {
        font-weight: 300;
        font-size: .75em;
        font-family: $text-font;
		color: white;
	}
}

.itome_tooltip {
	position: absolute;
	bottom: 105%;
	left: 50%;
	z-index: 10;

	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1.4em 1.25vw;
	border-radius: 15px;

	background: #fff;
	box-shadow: 2px 15px 20px rgba(0, 0, 0, .15);
	transform: translateX(calc(-50%)) translateY(1vh);
	opacity: 0;

	transition: all .4s;

	pointer-events: none;

	@media screen and (max-width: 480px) {
		display: none;
	}

	&:before {
		position: absolute;
		bottom: -13px;
		left: 50%;

		border: 7px solid transparent;
		border-top-color: white;

		transform: translateX(-50%);

		content: "";
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 4em;
		height: 4em;
		border-radius: 50%;

		background: linear-gradient(180deg, #006EFF 9.59%, #0059DC 99.64%);

		svg {
			position: relative;
			top: -1px;
			left: -1px;

			width: 50%;

			fill: white;
		}

	}

	&__title {
		margin: .9vh 0;

		color: #000;
		font-weight: 700;
		font-size: 1.15em;
	}

	&__subtitle {
		color: #B6B6B6;
		font-weight: 300;
		font-size: .875em;
		white-space: nowrap;
	}

}

.modal_menu .itome__title {
	color: #111115;
}