.page_top {
	display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    padding: em(10) em(20);
    transition: transform .3s .3s, opacity .3s .3s;

    .agencies & {
        padding-bottom: em(20);
    }
    

    &__upper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: transform .3s .3s, opacity .3s .3s;
    }

    &__arrow {
        width: 2em;
        height: 2em;
        padding: 0;

        .icon {
            width: 100%;
            height: 100%;
        }
    }

    &__bottom {
        display: flex;
        margin-top: em(12);
        align-items: center;
        transform: translate3d(3em, 0, 0);
        transition: transform .5s .6s, opacity .5s .6s;
        
        .simplebar-content {
            display: flex;

            padding-bottom: 1px !important;
        }

        .simplebar-horizontal {
            opacity: 0;
        }
        
    }

    &__btn {
        padding: 0.175em 0.75em 0.375em;
        background: white;
        border: 1px solid $c-green;
        border-radius: 24px;

        &.is-active {
            background: $c-green;

            pointer-events: none;
        }

        & + & {
            margin-left: 0.5em;
        }
    }

    &__btn_text {
        font-size: em(12);
        white-space: nowrap;
        font-family: $title-font;
        font-weight: 500;

        .is-active & {
            color: white;
        }
    }
}