.connect {
    margin-top: em(30);

    &__content {
        margin-top: 1.5em;
        background: #FFFFFF;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: em(16) em(20) em(25);
        display: flex;
        flex-direction: column;
        transition: transform .3s .6s, opacity .3s .6s;
    }
}