.reviews {
	margin-top: em(30);

    &__content {
        margin-top: 1.5em;
        background: #FFFFFF;
        box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: em(15);
        position: relative;

        transition: transform .3s .9s, opacity .3s .9s;

        .agencies_card__bottom {
            margin-top: 0;
        }
    }

    &__wrap {
        margin-top: 0.75em;
        max-height: 20em;

        transition: transform .3s 1.3s, opacity .3s 1.3s;

        .simplebar-content {
            width: calc(100% + 2em);
        }
        .simplebar-content-wrapper {
            padding-right: 2em;
        }

        .simplebar-track.simplebar-vertical {
            width: 4px;
            height: 100%;
            background: #eee;
            right: -0.6em;

            .simplebar-scrollbar {
                background: $c-primary;
                border-radius: 10px;
                width: 100%;
            }
        }
    }

}