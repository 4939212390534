.rating_modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    
    padding: 1.25em;

    height: unset;

    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    opacity: 0;

    transition: opacity .3s;

    .is-active & {
        pointer-events: all;

        opacity: 1;
    }

    .modal__close {
        position: absolute;

        top: 8px;
        right: 0px;

        .icon {
            fill: #000;
        }
    }

    &__header {
        // padding-bottom: .25em;
    }

    .line {        
        width: 100%;
        height: 1px;
        background: #EEEEEE;

        margin: 1em 0;
    }

    &__title {
        font-size: 1.125em;
        font-weight: 600;
        line-height: 1.33em;

        color: #151515;

        .blue {
            color: #1089F9;
        }
    }

    &__date {
        margin-top: .357em;

        font-size: .875em;
        font-weight: 300;
        line-height: 1.57em;
        color: #6F7588;

        .strong {
            font-weight: 600;
            color: #161E34;
        }
    }

    &__small_text {
        font-size: .625em;
        line-height: 1.4em;

        color: #6F7588;
    }

    &__check {
        display: flex;
        align-items: center;

        .image {
            width: 4.875em;
            height: 4.875em;
        }
    }

    &__right {
        margin-left: .875em;
    }

    &__check_title {
        font-size: 1.125em;
        font-weight: 600;
        line-height: 1.33;

        color: #161E34;
    }

    &__check_text {
        font-size: .875em;
        line-height: 1.29em;

        color: #6F7588;

        margin-top: .285em;
    }
}