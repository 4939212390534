.rating_modal_list_item {
    color: #6F7588;

    line-height: 1.25em;
    
    & + & {
        margin-top: 1em;
    }

    span {
        font-weight: 600;
    }
    
    .blue {
        color: #1089F9
    }

    .black {
        color: #161E34;
    }
}