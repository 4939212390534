.privacy {
	margin: 5em 0 5em;

	@media(max-width: 1350px) {
		font-size: .9em;
	}

	&__container {
		display: flex;
		align-items: flex-start !important;
		flex-direction: row;

		height: unset !important;
		padding-top: 5em;

	}

	&__content {
		display: flex;
		align-items: flex-start;
		width: 100%;

		@media(max-width: 500px) {
			flex-direction: column;
		}
	}

	&__title {
		margin-bottom: 0em;
    
		color: black;
		font-weight: 700;
		font-size: 2.75em;
		line-height: 1em;
		text-align: center;
    
		transform: translate3d(0, -1vh, 0);
		opacity: 1;
    
		transition: all .35s;
		max-block-size: 2.5em;
    
		&.is-inview {
			transform: none;
			opacity: 1;
		}
	}
}


.privacy_column {
	flex: 1;
	margin-right: auto;

	@media(max-width: 500px) {
		width: 100%;
		margin: 0 0 2em;
	}

	&__wrapper {
		
		& + & {
			margin-top: 3em;
		}
	}

	&__title {
		display: flex;
		align-items: flex-start;

		margin-bottom: .75em;

		color: black;
		font-weight: 700;
		font-size: 1.8em;

		line-height: 1.3em;
		white-space: nowrap;

		transform: translate3d(0, -1vh, 0);
		opacity: 1;

		transition: all .35s;

		@media(max-width: 500px) {
			font-size: 22px;
			white-space: normal;

			br {
				display: none;
			}
		}

		&.is-visible {
			transform: none;
			opacity: 1;
		}

		.counter {
			position: relative;
			top: 3px;

			display: flex;
			flex: none;
			align-items: center;
			justify-content: center;
			width: 2vw;
			height: 2vw;
			margin-right: 1vw;

			color: white;
			font-weight: 700;
			font-size: .6em;

			@media(max-width: 500px) {
				top: 0px;

				width: 8vw;
				height: 8vw;
				margin-right: 2vw;
			}

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				z-index: -1;

				width: 100%;
				height: 100%;
				border-radius: 50%;

				background: $c-primary;

				content: "";
			}
		}
	}

	&__lower {
		margin-bottom: 1.5em;
		padding-left: 3vw;

		color: #444444;
		font-weight: 300;
		font-size: 1em;
		line-height: 1.625;

		transform: translate3d(0, -1vh, 0);
		
		opacity: 1;

		transition: all .35s;

		@media(max-width: 1500px) {
		}

		@media(max-width: 1350px) {
		}

		@media(max-width: 500px) {
			padding-left: 10vw;

			font-size: 1.1em;
		}

		&.is-inview {
			transform: none;
			opacity: 1;
		}
	}

	&__text {
		padding-left: 3vw;

		color: #444444;
		font-weight: 300;
		font-size: 1em;
		line-height: 1.625;

		transform: translate3d(0, -1vh, 0);
		opacity: 1;

		transition: all .3s;

		@media(max-width: 1500px) {
			padding-right: 5vw;
		}

		@media(max-width: 1350px) {
			padding-right: 3vw;
		}

		@media(max-width: 500px) {
			padding-left: 10vw;

			font-size: 1.1em;

			& + & {
				margin-top: 1em;
			}
		}

		a {
			color: #4B88FF;
			text-decoration: underline;
		}

		& + & {
			margin-top: 1.5em;
		}

		&.is-inview {
			transform: none;
			opacity: 1;
		}

		.strong {
			color: black;
			font-weight: 700;
		}
	}
}

.privacy_pagination {
	position: sticky;
	top: 10em;

	display: flex;
	flex-direction: column;
	margin-right: 4vw;

	@media(max-width: 500px) {
		display: none;
	}

	&__link {
		flex: none;

		color: #7c7c7c;
		font-weight: 500;
		font-weight: 400;
		font-size: .875em;
		white-space: nowrap;

		transition: color .15s;

		&:hover {
			color: $c-primary;
		}

		&.is-active {
			color: black;
		}

		& + & {
			margin-top: 2em;
		}
	}
}

.privacy_block {
	flex: none;
	padding: 1.75em 2.5em;
	border-radius: 14px;

	box-shadow: inset 0 0 0 1px #DCDCDC;

	&__title {
		display: flex;
		align-items: center;
		margin-bottom: .25em;

		font-weight: 500;
		font-size: 1.2em;
	}

	&__text {
		margin-bottom: 1em;

		color: #404040;
		font-weight: 300;
		line-height: 1.8em;
	}

	&__button {
		padding: .8em 1.5em;
		border-radius: 6px;

		color: red;
		font-size: .9em;

		box-shadow: inset 0 0 0 1px #E0E0E0;

		&:hover {
			background: rgba(255, 21, 0, .06);
		}
	}
}