@import "_includes/_reset";

@import "_includes/_globals";

@import "_includes/_fonts";

@import "_includes/_bundle";

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	background: #f5f5f5;

	@media(max-width: 500px) {
		::-webkit-scrollbar {
			display: none; // Safari and Chrome
		}
	}
}

body {
	font-size: 1em;
	overflow-x: hidden;
}

.content {
	opacity: 0;

	.layout.disabled & {
		opacity: 1;
		// overflow-x: hidden;
	}

	.layout.is-loaded & {
		opacity: 1;
		// overflow-x: hidden;
	}
	
}

button,
a {
	transition: color .2s, background .2s; 
}

input,
textarea {
	-webkit-appearance: none;
}

img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.LazyLoad {
	opacity: 0;

	transition: opacity .125s;

	&.is-visible {
		opacity: 1;
	}
}

[data-scroll-fadeup],
.layout.is-loaded .is-loaded-enter-active [data-scroll-fadeup] {
	transform: translate3d(0, 3rem, 0);
	opacity: 0;

	transition: transform .3s, opacity .3s;

	will-change: transform;
	will-change: opacity;

	// .layout.is-loaded  &.is-inview {
	// 	transform: translate3d(0, 0 ,0);
	// 	opacity: 1;
	// }

	// &.is-inview {
	// 	transform: translate3d(0, 0 ,0);
	// 	opacity: 1;
	// }
}

.layout.is-loaded [data-scroll-fadeup] {
	transform: translate3d(0,0,0);
	opacity: 1;
}

.layout.is-loaded [data-scroll-fadeup]::before {
	transform: translate3d(0,0,0);
	opacity: 1;
}
.layout.disabled [data-scroll-fadeup] {
	transform: translate3d(0,0,0);
	opacity: 1;
}


[data-scroll-fadeup] .highlighted_text {
	color: black;

	transition: color .3s .15s;
	
}

[data-scroll-fadeup].is-inview .highlighted_text {
	color: $c-primary;
}

[data-scroll-fadedown] {
	transform: translate3d(0, -5rem, 0);
	opacity: 0;

	transition: transform .5s .25s, opacity .5s .25s;

	will-change: transform, opacity;

	&.is-inview {
		transform: translate3d(0, 0 ,0);
		opacity: 1;
	}
}

.view {
	position: relative;

	margin-top: em(30);
}

.section {
	transition: opacity .3s, transform .3s;

	// opacity: 1;

	&.is-loaded-exit-active {
		opacity: 0;
		transform: translate3d(0, 1rem, 0);
	}

	&.is-loaded-enter-active {
		position: absolute;

		top: 0;
		left: 0;
		right: 0;
		opacity: 0;

		transform: translate3d(0, 1rem, 0);

		transition: opacity .0s, transform .0s;
	}

	.is-loaded-enter-done {
		// opacity: 1;
		transition: opacity .3s .3s, transform .3s .3s;
	}
}

@keyframes loading {

	from {
		transform: scale3d(0, 1, 1);
	}

	to {
		transform: scale3d(1, 1, 1);   
	}
}

.video_modal {
	position: relative;

	display: flex;
	flex-direction: column;
	

	max-height: 90vh;

	height: unset;

	// background: #fff;
	// border-radius: 8px;

	pointer-events: all;

	opacity: 0;

	transition: opacity .35s;

	.is-active & {
		opacity: 1;
	}

	.modal__close {
		position: absolute;

		top: 0;
		right: 0;

		svg {
			fill: #fff;
		}
	}

	video {
		// width: 100%;
		max-height: 90vh;

		object-fit: contain;

		background: #fff;
		border-radius: 8px;
	}
}

@import "_blocks/container";

@import "_blocks/input_wrapper";

@import "_blocks/btn";

@import "_blocks/multi_select";

@import "_blocks/multi_option";

@import "_blocks/section";

@import "_blocks/section_block";

@import "_blocks/dropdown";

@import "_blocks/privacy";

@import "_blocks/modal";

@import "_blocks/modals";

@import "_blocks/modal_close";

@import "_blocks/modal_form";

@import "_blocks/modal_menu";

@import "_blocks/itome";

@import "_blocks/preloader";

@import "_blocks/header";

@import "_blocks/main";

@import "_blocks/main_info";

@import "_blocks/connect";

@import "_blocks/page_top";

@import "_blocks/connect_form";

@import "_blocks/connect_send";

@import "_blocks/comment_send";

@import "_blocks/request_send";

@import "_blocks/request_timeout";

@import "_blocks/form";

@import "_blocks/agencies";

@import "_blocks/agencies_card";

@import "_blocks/card";

@import "_blocks/reviews";

@import "_blocks/reviews_card";

@import "_blocks/reviews_form";

@import "_blocks/application_form";

@import "_blocks/checkbox";

@import "_blocks/inline_link";

@import "_blocks/rating";

@import "_blocks/rating_modal";

@import "_blocks/rating_modal_list_item";