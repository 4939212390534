.reviews_card {
	padding: em(12) em(16) em(16);
    border-radius: 8px;

    &--up {
        background: #F4FAFF;
    }
    &--down {
        background: #f9f9f9;
    }

    .section_block__text {
        font-size: em(12);
        line-height: em(16,12);
    }

    &__bottom {
        margin-top: 1em;
        padding-top: 0.75em;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .reviews_card--up & {
            border-top: 1px solid #E8F4FF;
        }
        .reviews_card--down & {
            border-top: 1px solid #eee;
        }
    }

    &__date {
        color: $c-text;
        font-size: 0.75em;
        line-height: em(12, 16);
    }

    & + & {
        margin-top: 0.75em;
    }
}