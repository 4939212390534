.main_info {
	display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.04);
    border-radius: 10px;
    padding: em(14) em(20) em(20);
    transition: transform .3s .7s, opacity .3s .7s;
    -webkit-transition: transform .3s .7s, opacity .3s .7s;
    
    .form & {
        transition: transform .3s 0s, opacity .3s 0s;
        -webkit-transition: transform .3s 0s, opacity .3s 0s;
    }

    &__list {
        margin-top: 0.5em;
        position: relative;

        &::before {
            display: block;
            position: absolute;
            content: '';
            width: 1.5px;
            height: 100%;
            background: $c-light-grey;
            left: 10px;
            // transform: scale3d(1, 0, 1);
            // transform-origin: bottom;
            // transition: transform .5s 2s, opacity .5s 2s;
            // opacity: 1;
        }
        // &.is-inview::before {
        //     // transform: none;
        // }

    }

    &__item {
        display: flex;
        align-items: center;
        
        .section_block__text {
            margin-top: 0;
        }

        & + & {
            margin-top: 0.5em;
        }

        &:nth-child(1) {
            
            .section_block__text {
                transition: transform .3s 1.3s, opacity .3s 1.3s;

                .form & {
                    transition: transform .3s .6s, opacity .3s .6s;
                }
            }
            .main_info__item_number {
                transition: transform .3s 1.4s, opacity .3s 1.4s;

                .form & {
                    transition: transform .3s .7s, opacity .3s .7s;
                }
            }
            
        }
        &:nth-child(2) {
            .section_block__text {
                transition: transform .3s 1.5s, opacity .3s 1.5s;

                .form & {
                    transition: transform .3s .8s, opacity .3s .8s;
                }
            }
            .main_info__item_number {
                transition: transform .3s 1.6s, opacity .3s 1.6s;

                .form & {
                    transition: transform .3s .9s, opacity .3s .9s;
                }
            }
        }

        &:nth-child(3) {
            .section_block__text {
                transition: transform .3s 1.7s, opacity .3s 1.7s;

                .form & {
                    transition: transform .3s 1s, opacity .3s 1s;
                }
            }
            .main_info__item_number {
                transition: transform .3s 1.8s, opacity .3s 1.8s;

                .form & {
                    transition: transform .3s 1.1s, opacity .3s 1.1s;
                }
            }
        }

        &:nth-child(4) {
            .section_block__text {
                transition: transform .3s 1.9s, opacity .3s 1.9s;

                .form & {
                    transition: transform .3s 1.2s, opacity .3s 1.2s;
                }
            }
            .main_info__item_number {
                transition: transform .3s 2s, opacity .3s 2s;

                .form & {
                    transition: transform .3s 1.3s, opacity .3s 1.3s;
                }
            }
        }

        
    }

    &__item_number {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background: #E8F4FF;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: none;
        margin-right: em(10);
        position: relative;
        transform: scale3d(0, 0, 1);
        
        span {
            font-size: em(12);
            font-weight: 600;
            line-height: 1em;
            color: $c-primary;
        }
    }
    
}