$bg-filled: $c-primary;
$borderR: 8px;

$paddingSide: 1.25em;
$height: em(56);

.btn {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1em $paddingSide;
	border-radius: $borderR;
	height: $height;

	font-weight: 700;
	line-height: 1em;

	transition: all .25s;

	&.disabled {
		background: $c-light-grey;
		color: $c-text;
	}

	.is-request & {
		pointer-events: none;
	}

	& + & {
		margin-left: 1em;
	}

	&__text {

	}

	&__icon {
		width: .8em;
		height: .8em;

		transform: scaleX(1);

		transition: transform .25s;

		&--white {
			fill: white;
		}

		&--black {
			fill: black;
		}

		&--left {
			margin-right: .5em;

			transform: scaleX(-1);
		}

		&--right {
			margin-left: .5em;
		}
	}

	&__pic {
		width: 1em;
		height: 1em;
		margin-left: 3em;

		transition: transform .25s;

		&--white {
			fill: white;
		}

		&--black {
			fill: black;
		}
	}

	&--filled {
		color: white;

		background: $bg-filled;

		&:hover {
			background: #0E7FE7;          
		}

		&:focus {
			background: #0E7FE7;
		}
	}

	&--green {
		color: white;
		background: $c-green;

		&:hover {
			background: darken($c-green, 5%);
		}
		&:focus {
			background: darken($c-green, 5%);
		}
	}

	&--outlined {
		box-shadow: inset 0 0 0 1px white;

		&:hover {
			box-shadow: inset 0 0 0 1px darken(white, 20%);           
		}
	}

	&--error {
		color: white;

		background: $c-error;

		&:hover {
			background: darken($c-error, 5%);           
		}

	}

	&--succes {
		color: white;

		background: $c-success;

		&:hover {
			background: darken($c-success, 5%);           
		}
	}

	.btn__text {
		.is-request & {
			opacity: 0;
			pointer-events: none;
		}
	}

	.is_loading {
		position: absolute;
		top: 50%;
		left: 50%;

		transform: translate3d(-50%, -50%, 0);

		opacity: 0;

		.is-request & {
			opacity: 1;
		}
	}
}